import { GridSortModel } from '@mui/x-data-grid';
import { GridComponent, GridComponentSource } from '../../../../../store/slices';
import { apiDownload, apiFetch } from '../../../../../utils/fetchUtils';

type UseGridScreenProps = {
    component: GridComponent;
    sortModel: GridSortModel;
    sharedStorage: Record<string, unknown>;
    hasFilter: boolean;
};

type useMultiNestingGridViewResult = {
    getGridSource: (currentPage: number, sortModel: GridSortModel) => Promise<GridComponentSource | undefined>;
    download: () => void;
};

const addPageParameters = (url: string): string => {
    const queryParameters = new URLSearchParams(window.location.search);
    return `${url}&${queryParameters.toString()}`;
};

const prepareFiltersParameters = (newFilterValues: Record<string, string>): string => {
    let existingFilters = '';
    for (const key in newFilterValues) {
        const value = newFilterValues[key];
        if (value && value !== '') {
            existingFilters = `${existingFilters}&${key}=${value}`;
        }
    }
    return existingFilters;
};

export const useMultiNestingGridView = (props: UseGridScreenProps): useMultiNestingGridViewResult => {
    const { component, sortModel, sharedStorage, hasFilter } = props;
    const { bffContextRoot, apiContextPath } = window.Props as Record<string, string>;

    const getGridSource = async (
        currentPage: number,
        sortModel: GridSortModel,
    ): Promise<GridComponentSource | undefined> => {
        if (!component.dataSource) {
            return;
        }

        const gridDataUrl = prepareGridDataUrl('getSource');
        const queryParameters = `${addEntityParam()}${addCurrentPageParam(currentPage)}${addSortModelParam(
            sortModel,
        )}${addFilterParam()}`;

        return await apiFetch<GridComponentSource>(`${gridDataUrl}${queryParameters}`);
    };

    const download = (): void => {
        const exportUrl = prepareGridDataUrl('export');
        const queryParameters = `${addEntityParam()}${addSortModelParam(
            sortModel,
        )}${addFilterParam()}${addExcelExportParam()}`;
        void apiDownload(`${exportUrl}${queryParameters}`);
    };

    const prepareGridDataUrl = (action: string): string => {
        return `${addPageParameters(
            `${bffContextRoot}/api${apiContextPath}/view/component/GridComponent/${action}?dataSource=${component.dataSource}&OffsetFetch=false`,
        )}`;
    };

    const addEntityParam = (): string => {
        if (component.entityId <= 0) {
            return '';
        }
        return `&entityId=${component.entityId}`;
    };

    const addCurrentPageParam = (currentPage: number): string => {
        if (currentPage <= 1) {
            return '';
        }
        return `&page=${currentPage - 1}&take=${component.rowsPerPage}`;
    };

    const addSortModelParam = (sortModel: GridSortModel): string => {
        if (sortModel.length <= 0) {
            return '';
        }
        return `&sort=${sortModel[0].field}&desc=${sortModel[0].sort === 'desc' ? 'true' : 'false'}`;
    };

    const addFilterParam = (): string => {
        if (!hasFilter) {
            return '';
        }
        const filtersParameter = prepareFiltersParameters(
            sharedStorage[`grid.${component.dataSource}.filter`] as Record<string, string>,
        );

        if (filtersParameter.length > 0) {
            return `&${filtersParameter}`;
        }
        return '';
    };

    const addExcelExportParam = (): string => {
        const title = new URLSearchParams(window.location.search)?.get('title') || '';
        if (!title) {
            return '';
        }
        return `&isExcelExport=true&title=${title}`;
    };

    return { getGridSource, download };
};
