import { Button } from '@mui/material';
import { DataGridPro, GridSortModel, GridSortDirection } from '@mui/x-data-grid-pro';
import { GridComponent } from 'apps/client/src/store/slices';
import classNames from 'classnames';
import React, { forwardRef, useEffect, useState } from 'react';
import { Tooltip } from '../../../common';
import { WkExcel } from '../../../icons/wkExcel';
import GridColumns from './gridColumns';
import useHandlers from './handlers';
import { useMultiNestingGridView } from './hooks/useMultiNestingGridview.hook';
import useStyles from './useStyles';

declare global {
    interface Window {
        gridValues: any;
    }
}

interface Filter {
    onLoad: string;
}

const HEADER_HEIGHT = 38;

type GridProps = {
    component: GridComponent;
    sharedStorage: Record<string, unknown>;
    classes?: {
        root?: string;
    };
    style?: React.CSSProperties;
    hasFilter: boolean;
    onAction?: any;
};

export const MultiNestingGridView = forwardRef<HTMLDivElement, GridProps>((props, ref) => {
    const classes = useStyles();
    const [rows, setData] = useState<any[]>([]);
    const { component, classes: externalClasses, onAction, sharedStorage, hasFilter } = props;
    var [currentPage, setCurrentPage] = useState(1);
    const defaultSortColumn = component.columns.find((column: any) => column.isDefaultSort);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: defaultSortColumn?.code ?? '',
            sort: (defaultSortColumn?.defaultSortDirectionDesc ? 'desc' : 'asc') as GridSortDirection,
        },
    ]);
    const [gridValues, setGridValues] = useState<Record<string, Record<string, string>>>({});
    const [maxRowCount, setMaxRowCount] = useState<number>();
    const [expandedRows, setExpandedRows] = useState<string[]>([]);
    const { getGridSource, download } = useMultiNestingGridView({
        component,
        sortModel,
        sharedStorage,
        hasFilter,
    });

    const fetchDataAndUpdateStateFilter = async (isSorting = false, newSortModel: GridSortModel) => {
        if (!isSorting) {
            currentPage = 1;
        }
        const result = await getGridSource(currentPage, newSortModel);
        setData(result?.data || []);
        setMaxRowCount(result?.rowsCount);
    };

    const {
        handleSortChange,
        handleCheckedBoxChange,
        handleButtonClick,
        handleLinkClick,
        handleOnDetailPanelExpandedRowIdsChange,
        getDetailPanelContent,
    } = useHandlers({
        gridValues,
        setGridValues,
        setExpandedRows,
        onAction,
        setData,
        fetchDataAndUpdateStateFilter,
        setSortModel,
        sharedStorage,
    });

    const fetchDataAndUpdateState = async () => {
        const result = await getGridSource(currentPage, sortModel);
        setData(rows.concat(result?.data));
        setMaxRowCount(result?.rowsCount);
    };

    useEffect(() => {
        const filter = sharedStorage[`grid.${component.dataSource}.filter`] as Filter | undefined;
        if (filter?.onLoad == 'false') {
            fetchDataAndUpdateStateFilter(false, sortModel);
            setCurrentPage(currentPage + 1);
        }
    }, [sharedStorage]);

    const onScrollEnd = async () => {
        if (rows && rows.length == maxRowCount) {
            return;
        }
        fetchDataAndUpdateState();
        setCurrentPage(currentPage + 1);
    };

    const gridColumns = GridColumns({
        component,
        gridValues,
        sortModel,
        handleCheckedBoxChange,
        handleLinkClick,
        handleButtonClick,
    });

    const headerArea = () => {
        window.gridValues = gridValues;
        return (
            <div className={classes.gridArea}>
                <div className={classes.gridButtonsArea}>
                    <Button
                        onClick={download}
                        disableRipple
                        data-testid="grid-export-to-excel-button"
                        classes={{
                            root: classes.gridIconButton,
                        }}>
                        <Tooltip title="Excel" placement="top" classes={{ tooltipPlacementTop: classes.buttonTooltip }}>
                            <WkExcel />
                        </Tooltip>
                    </Button>
                </div>
            </div>
        );
    };

    const generateUniqueId = () => {
        return '_' + Math.random().toString(36).substr(2, 9);
    };

    const getRowStyle = (params: any) => {
        if (params.row.entityid?.style == 'HighLightRow') {
            return classes.HighLightRow;
        }
        return '';
    };

    return (
        <div
            data-testid="page-grid"
            className={classNames(classes.root, externalClasses?.root, classes.hideSortIcon)}
            ref={ref}>
            <DataGridPro
                classes={{
                    root: classes.grid,
                }}
                slots={{
                    toolbar: headerArea,
                }}
                rows={rows}
                columns={gridColumns}
                columnHeaderHeight={HEADER_HEIGHT}
                getRowHeight={() => component.rowHeight ?? 'auto'}
                disableRowSelectionOnClick
                disableColumnSelector
                disableColumnMenu
                hideFooterPagination
                hideFooter
                sortingMode="server"
                onSortModelChange={handleSortChange}
                sortModel={sortModel}
                getRowId={(row) => row?.entityid?.value ?? generateUniqueId}
                onRowsScrollEnd={onScrollEnd}
                disableColumnResize={true}
                columnVisibilityModel={{
                    expand: false,
                    entityid: false,
                    viewName: false,
                }}
                getDetailPanelContent={component.isNestedGrid ? getDetailPanelContent : undefined}
                getDetailPanelHeight={() => 'auto' as const}
                detailPanelExpandedRowIds={expandedRows}
                onDetailPanelExpandedRowIdsChange={handleOnDetailPanelExpandedRowIdsChange}
                getRowClassName={getRowStyle}
                checkboxSelection={component.allowRowSelection}
            />
        </div>
    );
});

MultiNestingGridView.displayName = 'GridView';
