import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Menu, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ButtonComponent } from 'apps/client/src/store/slices';
import React, { forwardRef, useState, MouseEvent } from 'react';
import { AppTheme } from '../../../app';
import { ControlType } from '../../../common/types';

const useStyles = makeStyles<AppTheme>(() => ({
    div: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: '1px solid #ccc',
        paddingTop: '10px',
        flexGrow: 1,
    },
    button: {
        marginBottom: '10px',
        lineHeight: '28px',
        boxShadow: 'none',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '13px',
        height: '28px',
        marginLeft: '4px',
        marginRight: '5px',
        fontFamily: 'FireSans,Arial Regular, Arial',
    }
}));

interface ButtonProps {
    component: ButtonComponent;
}

export const ButtonView = forwardRef<HTMLDivElement, ButtonProps>((props, ref) => {
    const { component } = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [menuItems, setMenuItems] = useState<any[]>([]);

    const handleClick = (event: MouseEvent<HTMLElement>, items: any[]) => {
        setAnchorEl(event.currentTarget);
        setMenuItems(items);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setMenuItems([]);
    };

    return (
        <div ref={ref} className={classes.div}>
            {component.buttons.map((button, index) => {
                if (button.type === ControlType.Button) {
                    return (
                        <Button
                            disableRipple
                            color="primary"
                            variant={button.style}
                            key={index}
                            className={classes.button}>
                            {button.text}
                        </Button>
                    );
                }
                if (button.type === ControlType.ButtonWithDropDown) {
                    return (
                        <div key={index}>
                            <Button
                                disableRipple
                                color="primary"
                                variant={button.style}
                                endIcon={<ArrowDropDownIcon />}
                                className={classes.button}
                                onClick={(event) => handleClick(event, button.subMenus)}>
                                {button.text}
                            </Button>
                            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                                {menuItems.map((item, idx) => (
                                    <MenuItem key={idx} onClick={handleClose} sx={{ color: '#005B92' }}>
                                        {item.text}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </div>
                    );
                }
                return null;
            })}
        </div>
    );
});

ButtonView.displayName = 'ButtonView';
