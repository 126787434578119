import { GridSortModel } from '@mui/x-data-grid-pro';
import { GridEvents } from 'apps/client/src/store/slices/globalState.slice';
import React, { useEffect, useState } from 'react';
import { UseTreeViewContent } from '../treeView/treeView.component';

const useHandlers = (props: any) => {
    const [instance, setInstance] = useState<GridEvents | null>(null);

    const {
        gridValues,
        setGridValues,
        setExpandedRows,
        onAction,
        setData,
        fetchDataAndUpdateStateFilter,
        setSortModel,
        sharedStorage,
    } = props;

    useEffect(() => {
        const createImport = async () => {
            const importedModule = await import('../Invoicing/LineItem');
            setInstance(importedModule.default);
        };

        createImport();
    }, []);

    const handleSortChange = React.useCallback(
        (newSortModel: GridSortModel) => {
            if (newSortModel.length <= 0) {
                return;
            }
            setSortModel(newSortModel);
            fetchDataAndUpdateStateFilter(true, newSortModel);
        },
        [sharedStorage],
    );

    const handleCheckedBoxChange = (event: React.ChangeEvent<HTMLInputElement>, code: string, id: string): void => {
        setGridValues(() => {
            const newGridValues = { ...gridValues };
            if (!newGridValues[code]) {
                newGridValues[code] = {};
            }
            if (event.target.checked) {
                newGridValues[code][id] = 'true';
            } else {
                delete newGridValues[code][id];
            }
            return newGridValues;
        });
    };

    const onClick = async (action: string, entityId: string, data?: any): Promise<void> => {
        await instance?.ActionEvent({action, entityId, data, setData, setExpandedRows, onAction});
    };

    const handleButtonClick = async (button: any) => {
        instance?.ButtonClick(button, onClick);
    };

    const handleLinkClick = async (payload: any, action: any) => {
        instance?.LinkClick(payload, action, onClick);
    };

    const handleOnDetailPanelExpandedRowIdsChange = (newExpandedRowIds: any) => {
        const rowids = newExpandedRowIds;
        setExpandedRows(rowids);
    };

    const onActionFromChildGridToUpdateParent = (parentId: any, data?: any) => {
        instance?.UpdateParent(parentId, setData, setExpandedRows, data);
    };

    const getDetailPanelContent = ({ row }: any) => {
        if (row.expand != undefined && row.expand.value == 'True') {
            return (
                <UseTreeViewContent
                    row={row}
                    onActionFromChildGridToUpdateParent={(data: any) =>
                        onActionFromChildGridToUpdateParent(row.entityid.value, data)
                    }
                />
            );
        }

        return null;
    };

    return {
        handleSortChange,
        handleCheckedBoxChange,
        handleButtonClick,
        handleLinkClick,
        handleOnDetailPanelExpandedRowIdsChange,
        getDetailPanelContent,
    };
};

export default useHandlers;
